import React, { Component } from 'react';
import './BookService.css';
import BarberProfile from '../components/BarberProfile.js';
import Barber1 from '../assets/barber1.jpg';
import Barber2 from '../assets/barber2.jpg';
import Barber3 from '../assets/barber3.jpg';
import Barber4 from '../assets/barber4.jpg';
import Barber5 from '../assets/barber5.jpg';
import Barber6 from '../assets/barber6.jpg';
import ResponsiveBarberProfile from '../components/ResponsiveBarberProfile';

export default class BookService extends Component {
	constructor(props){
		super(props);
		this.state={
			firstName: null,
			lastName: null,
			number: null,
			emailAddress: null,
			dayOfAppointment: null,
			targetDayClass: null,
			timeOfAppointment: null,
			selectedBarber: null,
			bookingSlide: "bookingSlideContent",
			currentSlide: "selectBarber firstSlide",

			responsiveBarberSlide: "mobileBarbersContainer",
			responsiveIntro: "showContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",
			showMichael: "hideContent",
			showDaniel: "hideContent",
			showRyan: "hideContent",
			showHector: "hideContent",
			showBaraka: "hideContent",
			showDemitry: "hideContent",

			michael: "activeBarber",
			daniel: "chooseBarber",
			ryan: "chooseBarber",
			hector: "chooseBarber",
			baraka: "chooseBarber",
			demitry: "chooseBarber"
		}
	}

	handleUserNumber=(number)=>{
		this.setState({
		number: number 	
		})
	}	

	handleUserEmail=(email)=>{
		this.setState({
		emailAddress: email
		})
	}

	handleUserFName=(name)=>{
		this.setState({
			firstName: name
		})
	}

	handleUserLName=(name)=>{
		this.setState({
			lastName: name
		})
	}	

	backToResponsiveBarbers=()=>{
		this.setState({
		responsiveBarberSlide: "mobileBarbersContainer returnToBarbers",
		})	
	}

	showResponsiveBarbers=()=>{
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "showContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",			
		})
	}

	showResponsiveDateTime=()=>{
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",			
		})		
	}

	showResponsiveDetails=()=>{
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "showContent",
			responsiveConfirmation: "hideContent",			
		})		
	}	

	showResponsiveConfirmation=()=>{
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "showContent",			
		})		
	}	

	handleSelectedTime=(value)=>{
		this.setState({
			timeOfAppointment: value
		})
	}

	handleSelectedDay=(dayText, chosenDay)=>{
		this.setState({
			dayOfAppointment: dayText,
			targetDayClass: chosenDay
		})
	}

	activateFirstSlide=()=>{
		this.setState({
			currentSlide: "selectBarber firstSlide",
			michael: "activeBarber",
			daniel: "chooseBarber",
			ryan: "chooseBarber",
			hector: "chooseBarber",
			baraka: "chooseBarber",
			demitry: "chooseBarber"			
		})		
	}	

	activateSecondSlide=()=>{
		this.setState({
			currentSlide: "selectBarber secondSlide",
			michael: "chooseBarber",
			daniel: "activeBarber",
			ryan: "chooseBarber",
			hector: "chooseBarber",
			baraka: "chooseBarber",
			demitry: "chooseBarber"			
		})
	}

	activateThirdSlide=()=>{
		this.setState({
			currentSlide: "selectBarber thirdSlide",
			michael: "chooseBarber",
			daniel: "chooseBarber",
			ryan: "activeBarber",
			hector: "chooseBarber",
			baraka: "chooseBarber",
			demitry: "chooseBarber"			
		})
	}

	activateFourthSlide=()=>{
		this.setState({
			currentSlide: "selectBarber fourthSlide",
			michael: "chooseBarber",
			daniel: "chooseBarber",
			ryan: "chooseBarber",
			hector: "activeBarber",
			baraka: "chooseBarber",
			demitry: "chooseBarber"			
		})
	}

	activateFifthSlide=()=>{
		this.setState({
			currentSlide: "selectBarber fifthSlide",
			michael: "chooseBarber",
			daniel: "chooseBarber",
			ryan: "chooseBarber",
			hector: "chooseBarber",
			baraka: "activeBarber",
			demitry: "chooseBarber"			
		})
	}

	activateSixthSlide=()=>{
		this.setState({
			currentSlide: "selectBarber sixthSlide",
			michael: "chooseBarber",
			daniel: "chooseBarber",
			ryan: "chooseBarber",
			hector: "chooseBarber",
			baraka: "chooseBarber",
			demitry: "activeBarber"			
		})
	}

	handleShowBarberDiv=(barber)=>{
		if (barber === "michael"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "responsiveBarberOption",
			showDaniel: "hideContent",
			showRyan: "hideContent",
			showHector: "hideContent",
			showBaraka: "hideContent",
			showDemitry: "hideContent",			
			})
		}
		if (barber === "daniel"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "hideContent",
			showDaniel: "responsiveBarberOption",
			showRyan: "hideContent",
			showHector: "hideContent",
			showBaraka: "hideContent",
			showDemitry: "hideContent",			
			})
		}
		if (barber === "ryan"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "hideContent",
			showDaniel: "hideContent",
			showRyan: "responsiveBarberOption",
			showHector: "hideContent",
			showBaraka: "hideContent",
			showDemitry: "hideContent",			
			})
		}
		if (barber === "hector"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "hideContent",
			showDaniel: "hideContent",
			showRyan: "hideContent",
			showHector: "responsiveBarberOption",
			showBaraka: "hideContent",
			showDemitry: "hideContent",			
			})
		}
		if (barber === "baraka"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "hideContent",
			showDaniel: "hideContent",
			showRyan: "hideContent",
			showHector: "hideContent",
			showBaraka: "responsiveBarberOption",
			showDemitry: "hideContent",			
			})
		}
		if (barber === "demitry"){
			this.setState({
			responsiveBarberSlide: "mobileBarbersContainer revealResponsiveBarber",
			showMichael: "hideContent",
			showDaniel: "hideContent",
			showRyan: "hideContent",
			showHector: "hideContent",
			showBaraka: "hideContent",
			showDemitry: "responsiveBarberOption"		
			})
		}													

	}	

	dayToText(month){

		let day = month;	

		if (month > 6) {
			day = month - 7;
		}

		if (day === 0) {
			return "Sunday";
		}
		if (day === 1) {
			return "Monday";
		}
		if (day === 2) {
			return "Tuesday"
		}
		if (day === 3) {
			return "Wednesday"
		}
		if (day === 4) {
			return "Thursday"
		}
		if (day === 5) {
			return "Friday"
		}
		if (day === 6) {
			return "Saturday"
		}
	}	

	monthToText(date,month){

		if (month === 0 && date < 32) {
			return date + " January";
		}
		if (month === 0 && date > 31) {
			let difference = date - 31;
			return difference + " February";
		}

		if (month === 1 && date < 29) {
			return date + " February";
		}
		if (month === 1 && date > 28) {
			let difference = date - 28;
			return difference + " March";			
		}

		if (month === 2 && date < 32) {
			return date + " March"
		}
		if (month === 2 && date > 31) {
			let difference = date - 31;
			return difference + " April";					
		}

		if (month === 3 && date < 31) {
			return "April"
		}
		if (month === 3 && date > 30) {
			let difference = date - 30;
			return difference + " May";					
		}		

		if (month === 4 && date < 32) {
			return date + " May"
		}
		if (month === 4 && date > 31) {
			let difference = date - 31;
			return difference + " June";		
		}

		if (month === 5 && date < 31) {
			return date + " June"
		}
		if (month === 5 && date > 30) {
			let difference = date - 30;
			return difference + " July";		
		}

		if (month === 6 && date < 32) {
			return date + " July"
		}
		if (month === 6 && date > 31) {
			let difference = date - 31;
			return difference + " August";		
		}		

		if (month === 7 && date < 32) {
			return date + " August"
		}
		if (month === 7 && date > 31) {
			let difference = date - 31;
			return difference + " September";	
		}

		if (month === 8 && date < 31) {
			return date + " September"
		}
		if (month === 8 && date > 30) {
			let difference = date - 30;
			return difference + " October";	
		}

		if (month === 9 && date < 32) {
			return date + " October"
		}
		if (month === 9 && date > 31){
			let difference = date - 31;
			return difference + " November";				
		}


		if (month === 10 && date < 31) {
			return date + " November";
		}
		if (month === 9 && date > 30){
			let difference = date - 30;
			return difference + " December";				
		}


		if (month === 11 && date < 32) {
			return date + " December";
		}
		if (month === 11 && date > 31) {
			let difference = date - 31;
			return difference + " January";				
		}		
	}	

	barbersScreen=()=>{
		this.setState({
			bookingSlide: "bookingSlideContent goToBarbers"
		})
	}		

	detailsScreen=()=>{
		if (!this.state.dayOfAppointment && !this.state.timeOfAppointment) {
			alert("Please select a day and time for your appointment");
			return false;
		}

		if (!this.state.dayOfAppointment) {
			alert("Please select a day for your appointment");
			return false
		}

		if (!this.state.timeOfAppointment) {
			alert("Please select a time for your appointment");
			return false;
		}

		this.setState({
			bookingSlide: "bookingSlideContentDate goToDetails"			
		})
	}

	confirmationScreen=()=>{
		if (!this.state.firstName || !this.state.lastName || !this.state.number || !this.state.emailAddress){
			alert("Please fill in all required fields");
			return false;
		}
		this.setState({
			bookingSlide: "bookingSlideContentDetails goToConfirmation"			
		})		
	}

	selectMichael=()=>{
		this.setState({
			selectedBarber: "Michael Hensey",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"
		})

	}

	selectDaniel=()=>{
		this.setState({
			selectedBarber: "Daniel Hensworth",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"
		})		
	}	

	selectRyan=()=>{
		this.setState({
			selectedBarber: "Ryan Pitt",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"
		})		
	}

	selectHector=()=>{
		this.setState({
			selectedBarber: "Hector Rodrigo",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"			
		})
	}			

	selectBaraka=()=>{
		this.setState({
			selectedBarber: "Baraka Muntasir",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"			
		})
	}


	selectDemitry=()=>{
		this.setState({
			selectedBarber: "Demitry Pirlo",
			bookingSlide: "bookingSlideContentBarbers goToDateTime"			
		})

	}	

	responsiveSelectMichael=()=>{
		this.setState({
			selectedBarber: "Michael Hensey",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}

	responsiveSelectDaniel=()=>{
		this.setState({
			selectedBarber: "Daniel Hensworth",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}

	responsiveSelectRyan=()=>{
		this.setState({
			selectedBarber: "Ryan Pitt",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}

	responsiveSelectHector=()=>{
		this.setState({
			selectedBarber: "Hector Rodrigo",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}	

	responsiveSelectBaraka=()=>{
		this.setState({
			selectedBarber: "Baraka Muntasir",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}

	responsiveSelectDimitry=()=>{
		this.setState({
			selectedBarber: "Dimitry Pirlo",
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "showContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "hideContent",								
		})
	}

	handleMobileDay=(selectedDay)=>{
		this.setState({
			dayOfAppointment: selectedDay
		})
	}

	handleMobileTime=(selectedTime)=>{
		this.setState({
			timeOfAppointment: selectedTime
		})
	}

	responsiveDetails=()=>{
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "showContent",
			responsiveConfirmation: "hideContent",					
		})
	}

	responsiveConfirmation=()=>{
		if (!this.state.dayOfAppointment && !this.state.timeOfAppointment) {
			alert("Please select a day and time for your appointment");
			return false;
		}

		if (!this.state.dayOfAppointment) {
			alert("Please select a day for your appointment");
			return false
		}

		if (!this.state.timeOfAppointment) {
			alert("Please select a time for your appointment");
			return false;
		}
		
		this.setState({
			responsiveIntro: "hideContent",
			responsiveBarbers: "hideContent",
			responsiveDateTime: "hideContent",
			responsiveDetails: "hideContent",
			responsiveConfirmation: "showContent",					
		})		
	}															

	render(){

		const getDate = new Date();
		const currentDay = getDate.getDay();
		const currentDate = getDate.getDate();
		const currentMonth = getDate.getMonth();

	return (

		<div>

			<div className="responsiveAppointment">

				<div className={this.state.responsiveIntro}>

					<h1>APPOINTMENT BOOKING</h1>
					<p>Welcome to the section where you can book a scheduled appointment with one of our talented barbers at Ace Barbers. We have made the booking process in a step-by-step format, making it easy for you to provide the required details in order to secure your appointment.</p>

					<h3>TERMS & CONDITIONS:</h3>
					<p>Ace Barbers kindly ask that you provide a minimum of 24 hours’ notice for appointment cancellations. This allows us to potentially offer your appointment to another client and avoids your barber losing income.</p>
					
					<button className="responsiveBtn" onClick={this.showResponsiveBarbers}>CONTINUE</button>

				</div>


				<div className={this.state.responsiveBarbers}>

					<h1>SELECT YOUR BARBER</h1>					
					<ResponsiveBarberProfile
					navigateBack={this.backToResponsiveBarbers} 
					showMichael={this.state.showMichael}
					showDaniel={this.state.showDaniel}
					showRyan={this.state.showRyan}
					showHector={this.state.showHector} 
					showBaraka={this.state.showBaraka}
					showDemitry={this.state.showDemitry}
					selectMichael={this.responsiveSelectMichael}
					selectDaniel={this.responsiveSelectDaniel}										  					 			
					selectRyan={this.responsiveSelectRyan}
					selectHector={this.responsiveSelectHector}
					selectBaraka={this.responsiveSelectBaraka}
					selectDimitry={this.responsiveSelectDimitry}		  		
					barberSlideStatus={this.state.responsiveBarberSlide}
					revealBarberChoice={(e)=>this.handleShowBarberDiv(e.target.id)}/>

				</div>

				<div className={this.state.responsiveDateTime}>

					<h1>SELECT TIME & DATE</h1>

					<p>Please select a date and time for your appointment. We can only accept appointments to be made a maximum of one week in advance from today.</p>

					<div className="responsiveDate responsiveDiv">

						<span className="appHeading">Day of Appointment:</span>
						<select onChange={(e)=> this.handleMobileDay(e.target.value)}>

							<option>{this.dayToText(currentDay+1)} {this.monthToText(currentDate+1, currentMonth)}</option>
							<option>{this.dayToText(currentDay+2)} {this.monthToText(currentDate+2, currentMonth)}</option>	
							<option>{this.dayToText(currentDay+3)} {this.monthToText(currentDate+3, currentMonth)}</option>
							<option>{this.dayToText(currentDay+4)} {this.monthToText(currentDate+4, currentMonth)}</option>
							<option>{this.dayToText(currentDay+5)} {this.monthToText(currentDate+5, currentMonth)}</option>
							<option>{this.dayToText(currentDay+6)} {this.monthToText(currentDate+6, currentMonth)}</option>
							<option>{this.dayToText(currentDay+7)} {this.monthToText(currentDate+7, currentMonth)}</option>
																																															

						</select><br/><br/>

						<span className="appHeading">Time of Appointment:</span>

						<select onChange={(e)=> this.handleMobileTime(e.target.value)}>
							<option>9:00 - 10:00</option>
							<option>10:00 - 11:00</option>	
							<option>11:00 - 12:00</option>
							<option>12:00 - 13:00</option>
							<option>13:00 - 14:00</option>
							<option>14:00 - 15:00</option>
							<option>15:00 - 16:00</option>
							<option>16:00 - 17:00</option>
							<option>17:00 - 18:00</option>
							<option>18:00 - 19:00</option>																		
						</select>	

						<button onClick={this.responsiveDetails} className="responsiveBtn">CONFIRM DATE & TIME</button>						

					</div>

				</div>	


					<div className={this.state.responsiveDetails}>
					
					<h1>YOUR DETAILS</h1>

					<p>Please enter your details below as the final step to confirm booking. A confirmation of your appointment will be sent to the email provided.</p>						

					<span className="responsiveFormLabel">First Name:</span>
					<input type="name" onChange={(e)=> this.handleUserFName(e.target.value)} className="responsiveFormField"/>

					<span className="responsiveFormLabel">Last Name:</span>
					<input type="name" onChange={(e)=> this.handleUserLName(e.target.value)} className="responsiveFormField"/>

					<span className="responsiveFormLabel">Email:</span>
					<input type="name" onChange={(e)=> this.handleUserEmail(e.target.value)} className="responsiveFormField"/>

					<span className="responsiveFormLabel">Contact Number:</span>
					<input type="name" onChange={(e)=> this.handleUserNumber(e.target.value)} className="responsiveFormField"/>

					<p>By proceeding, you agree to the terms and conditions of Ace Barbers</p>

					<button onClick={this.responsiveConfirmation} className="responsiveBtn">CONFIRM BOOKING</button>			


					</div>

					<div className={this.state.responsiveConfirmation}>
					
						<h1>YOUR BOOKING DETAILS</h1>	

						<p>Thank you for booking your appointment with Ace Barbers! Please have a look below for the final details regarding your appointment. We look forward to seeing you!</p>
						<span className="responsiveSummaryHeading">Your Details Summary</span>
							<h4>Name:</h4>
							{this.state.firstName} {this.state.lastName}

							<h4>Email:</h4>
							{this.state.emailAddress}	

							<h4>Telephone:</h4>
							{this.state.number}


						<span className="responsiveSummaryHeading">Your Appointment Summary</span>						
							<h4>Selected Service:</h4>
							Hair Trim

							<h4>Appointment Date & Time:</h4>
							{this.state.dayOfAppointment} {this.state.timeOfAppointment}	

							<h4>Your Selected Barber:</h4>
							{this.state.selectedBarber}							

					</div>




			</div>

		{/* START OF DESKTOP VERSION */}

		<div className="bookerServiceContainer">

			<div className={this.state.bookingSlide}>

				<div className="sliderDiv">

					<h1>APPOINTMENT BOOKING</h1>
					<p>Welcome to the section where you can book a scheduled appointment with one of our talented barbers at Ace Barbers. We have made the booking process in a step-by-step format, making it easy for you to provide the required details in order to secure your appointment.</p>

					<p>Before proceeding, we kindly request for you to read the terms and conditions mentioned below.</p>

					<h3>TERMS & CONDITIONS:</h3>
					<p>Ace Barbers kindly ask that you provide a minimum of 24 hours’ notice for appointment cancellations. This allows us to potentially offer your appointment to another client and avoids your barber losing income.</p> 

					<p>Any less than 24 hours notice will result in a small but fair additional £10 fee, which is payable on your next visit. This simply acts as a deterrent to avoid last minute cancellations and people not showing up. We hope you also feel this is fair.</p>

					<p>Please be sure to be on time to avoid delaying the following client. If you are running late please kindly phone ahead: 0207 8370171. Please make sure you provide us with your correct contact details. Rest assured - we do not provide you personal details to any third parties.</p>

					<button onClick={this.barbersScreen}>PROCEED WITH BOOKING</button>

				</div>


				<div className="sliderDiv">

					<h1>SELECT YOUR BARBER</h1>

					<div className="desktopBarbers">

					<div className={this.state.currentSlide}>

						<BarberProfile 
						barberSelected={this.selectMichael} 
						barberImg={Barber1} 
						barberName="Michael Hensey" 
						barberSpeciality="Beard Design, Trims & Fades" 
						barberExperience="9 Years"
						highlightBarber={this.state.selectedBarber === "Michael Hensey" ? "activeBarberBtn" : "selectBarberBtn"}> 
						Michael is one of Ace Barbers most senior, popular and experienced memebrs. He has worked in highly successfull hairdressers from across the world and has built up a valuable amount of experience, as well as an outstanding reputation with diverse styling techniques and styles. 
						</BarberProfile>

						<BarberProfile
						barberSelected={this.selectDaniel} 
						barberImg={Barber2} 
						barberName="Daniel Hensworth" 
						barberSpeciality="Hair Styling & Shape Up" 
						barberExperience="12 Years"
						highlightBarber={this.state.selectedBarber === "Daniel Hensworth" ? "activeBarberBtn" : "selectBarberBtn"}> 
						Daniel's talents as a barber are a benchmark to strive towards. He has worked on many types of hairs yet has always delivered outstanding and accurate outcomes when it comes to customers requests or recommendations - weather completely changing the style or simply imporving it.
						</BarberProfile>	

						<BarberProfile 
						barberSelected={this.selectRyan} 
						barberImg={Barber3} 
						barberName="Ryan Pitt" 
						barberSpeciality="Hair Styling & Beard Shaping" 
						barberExperience="6 Years"
						highlightBarber={this.state.selectedBarber === "Ryan Pitt" ? "activeBarberBtn" : "selectBarberBtn"}> 
						Ryan is known for using advanced and creative methods to deliver immaculate finishes to beards and hair styling. He is very popular amongst customers who like to go for that unique look and continues proving that his methods of cuts and trims are some of the most effective and brilliant within the industry.
						</BarberProfile>	

						<BarberProfile 
						barberSelected={this.selectHector} 
						barberImg={Barber4} 
						barberName="Hector Rodrigo" 
						barberSpeciality="Shape Up, Beards & Fades" 
						barberExperience="15 Years"
						highlightBarber={this.state.selectedBarber === "Hector Rodrigo" ? "activeBarberBtn" : "selectBarberBtn"}>
						Hector is one of Ace Barbers most requested hairdressers for a reason - he continues elevating his game through continuesly adding new techniques to his ways and consequently delivers the most trendy and slickest of haircuts and beard shaping. A true natural when it comes to being a barber.
						</BarberProfile>

						<BarberProfile
						barberSelected={this.selectBaraka} 
						barberImg={Barber5} 
						barberName="Baraka Muntasir" 
						barberSpeciality="Hair Styling, Beard Shaping & Trims" 
						barberExperience="5 Years"
						highlightBarber={this.state.selectedBarber === "Baraka Muntasir" ? "activeBarberBtn" : "selectBarberBtn"}> 
						Baraka is truly multi-talented when it comes to working as a barber. He is known for being able to adjust any hairstyle or beard to compliment the customers physical traits, and can deliver the most complex designs for beard and hair trimming with ease and precision.
						</BarberProfile>	

						<BarberProfile 
						barberSelected={this.selectDemitry}
						barberImg={Barber6}
						barberName="Demitry Pirlo"
						barberSpeciality="Hair Styling & Trimming"
						barberExperience="11 Years"
						highlightBarber={this.state.selectedBarber === "Demitry Pirlo" ? "activeBarberBtn" : "selectBarberBtn"}> 
						Demitry is a barber who brings his own unique yet highly effective methods of trimming and styling hairs. He is highly talented and admired for his ability to deliver sleek finishes with scissors or clippers. He is a versatile barber who can deliver with any request and is known for his creative ability.
						</BarberProfile>										

					</div>	

					</div>

					<div className="toggleBarbers">
							<button className={this.state.michael} onClick={this.activateFirstSlide}>Michael</button>
							<button className={this.state.daniel} onClick={this.activateSecondSlide}>Daniel</button>
							<button className={this.state.ryan} onClick={this.activateThirdSlide}>Ryan</button>
							<button className={this.state.hector} onClick={this.activateFourthSlide}>Hector</button>
							<button className={this.state.baraka} onClick={this.activateFifthSlide}>Baraka</button>
							<button className={this.state.demitry} onClick={this.activateSixthSlide}>Demitry</button>
					</div>

				</div>

				<div className="sliderDiv">

					<h1>SELECT TIME & DATE</h1>

					<p>Please select a date and time for your appointment. We can only accept appointments to be made a maximum of one week in advance from today.</p>

					<div className="dateTimeSelection">
						
						<div className="daySelection">
							<h3>DAY OF APPOINTMENT</h3>
							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+1)} 
							className={this.state.targetDayClass === currentDay+1 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+1)} {this.monthToText(currentDate+1, currentMonth)}</span>

							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+2)} 
							className={this.state.targetDayClass === currentDay+2 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+2)} {this.monthToText(currentDate+2, currentMonth)}</span>

							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+3)} 
							className={this.state.targetDayClass === currentDay+3 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+3)} {this.monthToText(currentDate+3, currentMonth)}</span>

							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+4)} 
							className={this.state.targetDayClass === currentDay+4 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+4)} {this.monthToText(currentDate+4, currentMonth)}</span>

							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+5)} 
							className={this.state.targetDayClass === currentDay+5 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+5)} {this.monthToText(currentDate+5, currentMonth)}</span>

							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+6)} 
							className={this.state.targetDayClass === currentDay+6 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+6)} {this.monthToText(currentDate+6, currentMonth)}</span>																										
							<span onClick={(e)=> this.handleSelectedDay(e.target.textContent, currentDay+7)} 
							className={this.state.targetDayClass === currentDay+7 ? "dayList selectedDateTime" : "dayList"}>
							{this.dayToText(currentDay+7)} {this.monthToText(currentDate+7, currentMonth)}</span>
						</div>

						<div className="timeSelection">
							<h3>TIME OF APPOINTMENT</h3>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "9:00"?"timeList selectedDateTime":"timeList"}>9:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "9:30"?"timeList selectedDateTime":"timeList"}>9:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "10:00"?"timeList selectedDateTime":"timeList"}>10:00</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "10:30"?"timeList selectedDateTime":"timeList"}>10:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "11:00"?"timeList selectedDateTime":"timeList"}>11:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "11:30"?"timeList selectedDateTime":"timeList"}>11:30</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "12:00"?"timeList selectedDateTime":"timeList"}>12:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "12:30"?"timeList selectedDateTime":"timeList"}>12:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "13:00"?"timeList selectedDateTime":"timeList"}>13:00</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "13:30"?"timeList selectedDateTime":"timeList"}>13:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "14:00"?"timeList selectedDateTime":"timeList"}>14:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "14:30"?"timeList selectedDateTime":"timeList"}>14:30</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "15:00"?"timeList selectedDateTime":"timeList"}>15:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "15:30"?"timeList selectedDateTime":"timeList"}>15:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "16:00"?"timeList selectedDateTime":"timeList"}>16:00</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "16:30"?"timeList selectedDateTime":"timeList"}>16:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "17:00"?"timeList selectedDateTime":"timeList"}>17:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "17:30"?"timeList selectedDateTime":"timeList"}>17:30</span>

							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)}
							className={this.state.timeOfAppointment === "18:00"?"timeList selectedDateTime":"timeList"}>18:00</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "18:30"?"timeList selectedDateTime":"timeList"}>18:30</span>
							<span onClick={(e)=> this.handleSelectedTime(e.target.textContent)} 
							className={this.state.timeOfAppointment === "19:00"?"timeList selectedDateTime":"timeList"}>19:00</span>

						</div>

				</div>	

			<button onClick={this.detailsScreen}>CONFIRM DATE & TIME</button>

			</div>

				<div className="sliderDiv">

					<h1>YOUR DETAILS</h1>

					<p>Please enter your details below as the final step to confirm booking. A confirmation of your appointment will be sent to the email provided.</p>
	
					<div className="formNames">
						<div>First Name: <input type="name" onChange={(e)=> this.handleUserFName(e.target.value)} className="contactForm"/></div>
						<div>Last Name: <input type="name"  onChange={(e)=> this.handleUserLName(e.target.value)}  className="contactForm"/></div> 
							
					</div>

					<div className="formNames">
						<div>Email Address: <input onChange={(e)=> this.handleUserEmail(e.target.value)} type="name" className="contactForm"/></div>
						<div>Contact Number: <input onChange={(e)=> this.handleUserNumber(e.target.value)} type="name" className="contactForm"/></div> 	
					</div>
			
					<button onClick={this.confirmationScreen}>CONFIRM BOOKING</button>

				</div>

				<div className="sliderDiv">

					<h1>YOUR BOOKING DETAILS</h1>

					<p>Thank you for booking your appointment with Ace Barbers! Please have a look below for the final details regarding your appointment. We look forward to seeing you!</p>

					<div className="summaryBox">
						<span className="summaryHeading">Your Details Summary</span>
							<div className="summaryBreakdown">
								
								<div className="summaryDetail">
									<h4>Name:</h4>
									{this.state.firstName} {this.state.lastName}
								</div>

								<div className="summaryDetail">
									<h4>Email:</h4>
									{this.state.emailAddress}
								</div>

								<div className="summaryDetail">
									<h4>Telephone:</h4>
									{this.state.number}
								</div>

							</div>
					</div>

					<div className="summaryBox">
						<span className="summaryHeading">Your Appointment Summary</span>
							<div className="summaryBreakdown">
								
								<div className="summaryDetail">
									<h4>Selected Service:</h4>
									{this.props.selectedService}
								</div>

								<div className="summaryDetail">
									<h4>Appointment Schedule:</h4>
									{this.state.dayOfAppointment} @ {this.state.timeOfAppointment}
								</div>

								<div className="summaryDetail">
									<h4>Your Selected Barber:</h4>
									{this.state.selectedBarber}
								</div>

							</div>
					</div>					

				</div>				

		</div>

	</div>

</div>
		)
	}

}