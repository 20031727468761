import React, { Component } from 'react';
import "./ProductTemplate.css";

const ProductTemplate=(props)=> {
	return (
		<div className="ProductTemplate">
			<img src={props.productImage} alt="Hair Essentials" /> 
			<h2>{props.productName}</h2>
				<div className="productDescriptionPrice">
				<p>{props.children}</p>
				<span className="productPrice">£{props.productPrice}</span>
				</div>
			<button className={props.key} onClick={props.addItem}>ADD TO BASKET</button>
		</div>
		)
}

export default ProductTemplate;