import React, { Component } from 'react'; 
import './PaymentComponent.css';
import Cards from '../assets/acceptedCards.png';

const PaymentComponent=(props)=>{

	return (
		<div className="paymentComponentContainer">
			
			<div className="paymentTemplate">

				<form className="paymentForm">

					<h1>PAYMENT CHECKOUT</h1>
					<img src={Cards} alt="Payment Cards" />

					<span className="formLabel">Name On Card:</span>
					<input onChange={props.handleCardName} type="text" value={props.cardName}/>

					<span className="formLabel">Card Number:</span>
					<input onChange={props.handleCardNumber} type="text"/>

					<span className="formLabel">Expiration Date:</span>
						<div className="expDate">
							<input maxlength="2" onChange={props.handleCardMonth} type="text" placeholder="MM"/>
							<input maxlength="4" onChange={props.handleCardYear}  type="text" placeholder="YYYY"/>
						</div>


				<button onClick={props.completePayment}>PAY £{Number(props.totalPayment).toFixed(2)}</button>		

				</form>

			</div>

		</div>
		)
}

export default PaymentComponent;