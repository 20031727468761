import React, { Component } from 'react';
import './WalkInServices.css';

const WalkInServicesKids=()=>{
	return (
		<div className="walkInServicesTemplateKids">

			<div className="walkInservicesDiv">

				<h2>CHILDRENS SERVICES</h2>

				<p>Shape Up: £3.00</p>
				<p>Basic Cut: £5</p>
				<p>Comb Over: £10</p>				
				<p>Design/Patterns: From £10</p>
				<p>High Top: £10</p>
				<p>Mohican: £10</p>	
				<p>Afro Cut: From £10</p>	
				<p>Scissor Cut: From £10</p>
				<p>Black Spray: £2</p>	
				<p>Sponge Twist: £2</p>

			</div>

		</div>
	)
}

export default WalkInServicesKids;