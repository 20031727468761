import React, { Component } from 'react';
import '../App.css';


const ContactUs=()=> {
	return (
		<div className="contactTemplate">

			<div className="contactBanner">

				<div className="contactDetailsTemplate">

					<div className="contactHeader">
						<span>CONTACT US</span>
					</div>

					<div className="contactTel contactDiv">
						<h3>Telephone</h3>
						<span className="contactInfo">020 3643 2055</span>
					</div>

					<div className="contactTel contactDiv">
						<h3>Private bookings</h3>
						<span className="contactInfo">Patrickanthonyphipps@gmail.com</span>
					</div>					

					<div className="contactaddress contactDiv">
						<h3>Our address</h3>
						<span className="contactInfo">339 Barking Rd, E6 1LA, London, United Kingdom</span>
					</div>

					<div className="contactaddress contactDiv">
						<h3>Opening / Closing Hours</h3>
						<span className="contactInfo">Monday to Saturday from 10am to 7pm<br/>Sunday from 12pm to 6pm</span>
					</div>


					<div className="contactaddress contactDiv">
						<h3>Instagram</h3>
						<span className="contactInfo">cutabovetherestuk</span>
					</div>


				</div>

			</div>

		</div>
	)
}

export default ContactUs;