import React, { Component } from 'react';
import Banner from '../assets/homebanner.jpg';
import AwardOne from '../assets/award1.png';
import AwardTwo from '../assets/award2.jpg';
import AwardThree from '../assets/award3.jpg';
import Product4 from '../assets/beard3.jpg';
import Services from '../assets/patcutting.jpg';
import Staff from '../assets/image.JPG';
import Inside from '../assets/patcut1.jpg';
import Tatoo from '../assets/tatooWallpaper.jpg';
import Rec from '../assets/recruitment.jpg';
import './homeBanner.css';
import '../App.css';

class HomeBanner extends Component {
	constructor(props){
		super(props);
		this.state = {

		}
	}


render(){
	return (
		<div className="BannerContainer">	


			<div className="DesktopBanner">

			</div>

			<div className="bannerText">
				<span className="bannerHeading">STYLISH | PRECISE | IMMACULATE</span>
				<span className="bannerBtn">CUT ABOVE THE REST</span>
			</div>

				<section className="mainIntro">

				<div className="introText">
					<h1 className="introHeading">THE MISSION & PHILOSOPHY</h1>
					<p>Welcome to Cut Above The Rest, born in 1991 and rowing with over 24 years standing. Cut Above the Rest is a highly successful and established barbers shop based in East London which is focused on making you look and feel great!</p>

					<p>With an extremely talented group of barbers who perform their duties with passion and immaculate precision, Cut Above The Rest have mastered the craft of perfecting the art of barbering, which includes services such as; Hair care, Haircuts for both Afro & European hair, beard care & grooming, male grooming services, as well as designer and pattern cuts.</p> 

						<p className="hideSentence">Not only do Cut Above The Rest's barbers believe in delivering the absolute best with every paying customer, but they also offer outstanding customer service from a personal and professional stand point.</p>

					<p>This website includes sections on some of the the services Cut Above The Rest provide, as well as pricing, contact information and some background details on the successful barber shop itself.</p>
				</div>	

				</section>

			<div className="mainServices">

				<div className="services-img">
					<img src={Services} alt="Services" />
				</div>

				<div className="servicesInfo">
						<h1 className="sectionHeading"><i class="fas fa-cut"></i> SERVICE PRICES</h1>
						<p>We offer a comprehensive range of services which help our customers choose from several diverse styles, the latest trends and male grooming. Our experienced barbers are known to deliver excellent results based on these services as well as specific requests. We also offer bespoke services as well as location services (Please enquire for more information).</p>

						<button onClick={this.props.goToWalkInServices} className="contentBtn">VIEW MORE</button>
				</div>

			</div>

			<div className="mainServices">

				<div className="servicesInfo order2">
						<h1 className="sectionHeading"><i class="fas fa-cut"></i> CHILDRENS PRICES</h1>
						<p>Along with our regular services, we also offer a comprehensive range of services for children. Some of these services include shape-up, design patterns, Mohicans, scissor cut and sponge twists. Parents are welcomed to enquire about any other type of specific service or requirement.</p> 

						<button onClick={this.props.goToManagementTeam} className="contentBtn">VIEW MORE</button>
				</div>

				<div className="services-img order1">
					<img src={Staff} alt="Services" />
				</div>				

			</div>

			<div className="mainServices">

				<div className="services-img">
					<img src={Inside} alt="Services" />
				</div>				


				<div className="servicesInfo">
						<h1 className="sectionHeading"><i class="fas fa-cut"></i> BEYOND THE BARBERS CHAIR</h1>
						<p>Cut Above The Rest has been a long established barbers shop which has built up a strong reputation based on its services, the quality of cuts provided and its continued growth.</p>
						<p>The store is operted by Patrick Phipps, who has developed on Cut Above The Rest's success whilst continuing to make further developments to benefit its customers.</p>

						<button onClick={this.props.goToTheLounge} className="contentBtn">VIEW MORE</button>
				</div>
 
			</div>

			<div className="mainServices">

				<div className="servicesInfo order2">
						<h1 className="sectionHeading"><i class="fas fa-cut"></i> PIERCING & TATOO SERVICE</h1>
						<p>Cut Above The Rest offer highly stylish tatoo designs and deliver precise tatoo piercing requirements as an extended and popular service. The talented staff are able to meet different design requirements and needs with each respective service, as many past satisfied customer have experienced.</p> 

						<button onClick={this.props.goToTatoo} className="contentBtn">VIEW MORE</button>
				</div>

				<div className="services-img order1">
					<img src={Tatoo} alt="Services" />
				</div>				

			</div>


			<div className="mainServices">

				<div className="services-img">
					<img src={Rec} alt="Services" />
				</div>				


				<div className="servicesInfo">
						<h1 className="sectionHeading"><i class="fas fa-cut"></i> RECRUITMENT & CAREERS</h1>
						
						<p>Cut Above The Rest has been a long established barbers shop which has built up a strong reputation based on its services, the quality of cuts provided and its continued growth.</p>
						<p>The store is operted by Patrick Phipps, who has developed on Cut Above The Rest's success whilst continuing to make further developments to benefit its customers.</p>

						<button onClick={this.props.goToRecruitment} className="contentBtn">VIEW MORE</button>
				</div>
 
			</div>			
				
           <footer className="footer">&copy; Cut Above The Rest
           <a href="mailto:azeem.webdev@gmail.com"><span className="sigText">Website designed and developed by Azeem</span></a>


           </footer>   

        

		</div>
		)
}



}

export default HomeBanner;