import React, {Component} from 'react';
import "./BasketPage.css";

class BasketComponent extends Component {
	constructor(props){
		super(props);
	}
	render(){
		return (
			<div className="basketContainer">
				<ul className="basketHeading checkoutList">
					<li>PRODUCT</li>
					<li>PRICE</li>
					<li>QUANTITY</li>
					<li>TOTAL</li>
				</ul>

				{this.props.children}

				<div className="subTotalCheckout">

					<span onClick={this.props.loadCheckoutPage} className="subTotalDiv">CHECKOUT (SUBTOTAL: £{Number(this.props.totalPrice).toFixed(2)})</span>		

				</div>

			</div>
			)
	}
}

export default BasketComponent;