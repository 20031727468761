import React, { Component } from 'react';
import './BookService.css';
import Barber1 from '../assets/barber1.jpg';
import Barber2 from '../assets/barber2.jpg';
import Barber3 from '../assets/barber3.jpg';
import Barber4 from '../assets/barber4.jpg';
import Barber5 from '../assets/barber5.jpg';
import Barber6 from '../assets/barber6.jpg';



const BarberProfile=(props)=>{
	return (
		<div className="barberComponent">
			<div className="barberImg">
				<img src={props.barberImg} alt="Ace Barber Staff" />
			</div>
			<div className="barberInfo">
				<span className="boldTag">Name:</span> {props.barberName}<br/>
				<span className="boldTag">Speciality:</span> {props.barberSpeciality}<br/>
				<span className="boldTag">Experience:</span> {props.barberExperience}<br/>

				<p>{props.children}</p>

				<button onClick={props.barberSelected} className={props.highlightBarber}>CHOOSE THIS BARBER</button>

			</div>
		</div>
		)
}

export default BarberProfile;