import React, { Component } from 'react';
import './LookBook.css';
import Client1 from '../assets/lookBook/pic1.jpg';
import Client1m from '../assets/lookBook/pic1m.jpg';
import Client2 from '../assets/lookBook/pic2.jpg';
import Client2m from '../assets/lookBook/pic2m.jpg';
import Client3 from '../assets/lookBook/pic3.jpg';
import Client3m from '../assets/lookBook/pic3m.jpg';
import Client4 from '../assets/lookBook/pic4.jpg';
import Client4m from '../assets/lookBook/pic4m.jpg';
import Client5 from '../assets/lookBook/pic5.jpg';
import Client6 from '../assets/lookBook/pic6.jpg';
import Client7 from '../assets/lookBook/pic7.jpg';
import Client8 from '../assets/lookBook/pic8.jpg';
import Client9 from '../assets/lookBook/pic9.jpg';
import Client10 from '../assets/lookBook/pic10.jpg';
import Client11 from '../assets/lookBook/pic11.jpg';
import Client12 from '../assets/lookBook/pic12.jpg';
import Client13 from '../assets/lookBook/pic13.jpg';
import Client14 from '../assets/lookBook/pic14.jpg';
import Client15 from '../assets/lookBook/pic15.jpg';
import Client16 from '../assets/lookBook/pic16.jpg';
import Client17 from '../assets/lookBook/pic17.jpg';
import Client18 from '../assets/lookBook/pic18.jpg';
import Client19 from '../assets/lookBook/pic19.jpg';
import Client20 from '../assets/lookBook/pic20.jpg';
import Client21 from '../assets/lookBook/pic21.jpg';
import Client22 from '../assets/lookBook/pic22.jpg';
import Client23 from '../assets/lookBook/pic23.jpg';
import Client24 from '../assets/lookBook/pic24.jpg';

export default class LookBook extends Component {
	constructor(props){
		super(props);
		this.state= {
			slideName: "slider",
			rSlideCount: 0,
			lSlideCount: 0,
			rightArrow: "fas fa-chevron-right",
			leftArrow: "fas fa-chevron-left",
			hideArrow: "hideContent"
		}
	}

	moveSlideRight=()=>{

		if (this.state.slideName === "slider") {
		this.setState({
			slideName: "slider1",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1	
			})
		} 

		if (this.state.slideName === "slider1") {
			this.setState({
			slideName: "slider2",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1				
			})		
		}

		if (this.state.slideName === "slider2") {
			this.setState({
			slideName: "slider3",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1				
			})		
		}
		if (this.state.slideName === "slider3") {
			this.setState({
			slideName: "slider4",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1				
			})		
		}
		if (this.state.slideName === "slider4") {
			this.setState({
			slideName: "slider5",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1				
			})		
		}
		if (this.state.slideName === "slider5") {
			this.setState({
			slideName: "slider6",
			rSlideCount: this.state.rSlideCount+1,
			lSlideCount: this.state.lSlideCount+1				
			})		
		}		

	}


	moveSlideLeft=()=>{
		
		if (this.state.slideName === "slider1") {
		this.setState({
			slideName: "slider",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1					
			})
		} 

		if (this.state.slideName === "slider2") {
			this.setState({
			slideName: "slider1",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1				
			})		
		}

		if (this.state.slideName === "slider3") {
			this.setState({
			slideName: "slider2",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1				
			})		
		}
		if (this.state.slideName === "slider4") {
			this.setState({
			slideName: "slider3",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1				
			})		
		}
		if (this.state.slideName === "slider5") {
			this.setState({
			slideName: "slider4",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1				
			})		
		}
		if (this.state.slideName === "slider6") {
			this.setState({
			slideName: "slider5",
			rSlideCount: this.state.rSlideCount-1,
			lSlideCount: this.state.lSlideCount-1				
			})		
		}

	} 			


	render(){

		return (

		<div className="lookBookMainContainer">

			<div className="lookBookBanner">

			</div>

			<div className="lbContainer">

			<div className="lookBookIntro">

				<h1>THE LOOK BOOK</h1>

				<p>This look book covers numerous hair styles in Afro, Asian and European hair. These images show fades, patterns, colour work, styling and children’s hair-cuts.</p>

				<p>Please inquire for more information on prices and patterns that are available.</p>		

			</div>

			</div>


			{/*<div className="lookBookMobile">

				<div className="lookBookContainer">

					<img src={Client1} alt="exampleImg" />
					<img src={Client2} alt="exampleImg" />

				</div>		

				<div className="lookBookContainer">

					<img src={Client3} alt="exampleImg" />
					<img src={Client4} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client5} alt="exampleImg" />
					<img src={Client6} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client7} alt="exampleImg" />
					<img src={Client8} alt="exampleImg" />

				</div>																


				<div className="lookBookContainer">

					<img src={Client9} alt="exampleImg" />
					<img src={Client10} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client11} alt="exampleImg" />
					<img src={Client12} alt="exampleImg" />

				</div>		

				<div className="lookBookContainer">

					<img src={Client13} alt="exampleImg" />
					<img src={Client14} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client15} alt="exampleImg" />
					<img src={Client16} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client17} alt="exampleImg" />
					<img src={Client18} alt="exampleImg" />

				</div>	

				<div className="lookBookContainer">

					<img src={Client19} alt="exampleImg" />
					<img src={Client20} alt="exampleImg" />

				</div>		

				<div className="lookBookContainer">

					<img src={Client21} alt="exampleImg" />
					<img src={Client22} alt="exampleImg" />

				</div>		

				<div className="lookBookContainer">

					<img src={Client23} alt="exampleImg" />
					<img src={Client24} alt="exampleImg" />

				</div>																													

			</div>*/}


			<div className="mobileLookBook">

				<div className="lbMImg">
					<img src={Client1} alt="Look Book Sample" />
				</div>

				<div className="lbMImg">
					<img src={Client2} alt="Look Book Sample" />
				</div>				

				<div className="lbMImg">
					<img src={Client3} alt="Look Book Sample" />
				</div>

				<div className="lbMImg">
					<img src={Client4} alt="Look Book Sample" />
				</div>		

				<div className="lbMImg">
					<img src={Client5} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client6} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client7} alt="Look Book Sample" />
				</div>

				<div className="lbMImg">
					<img src={Client8} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client9} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client10} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client11} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client12} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client13} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client14} alt="Look Book Sample" />
				</div>																																																	
				<div className="lbMImg">
					<img src={Client15} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client16} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client17} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client18} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client19} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client20} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client21} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client22} alt="Look Book Sample" />
				</div>		

				<div className="lbMImg">
					<img src={Client23} alt="Look Book Sample" />
				</div>	

				<div className="lbMImg">
					<img src={Client24} alt="Look Book Sample" />
				</div>		


			</div>
			



			<div className="lookBookDesktopSection">


				<div className="arrows leftArrowDiv">

					<i onClick={this.moveSlideLeft} className={this.state.lSlideCount === 0 ? this.state.hideArrow : this.state.leftArrow}></i>

				</div>


				<div className="slideShow">

					<div className={this.state.slideName}>

						<div className="sliderFrame sliderOne">
							<img src={Client1} alt="exampleImg" />
							<img src={Client2} alt="exampleImg" />					
							<img src={Client3} alt="exampleImg" />
							<img src={Client4} alt="exampleImg" />
						</div>

						<div className="sliderFrame sliderTwo">
							<img src={Client5} alt="exampleImg" />
							<img src={Client6} alt="exampleImg" />					
							<img src={Client7} alt="exampleImg" />
							<img src={Client8} alt="exampleImg" />
						</div>


						<div className="sliderFrame sliderThree">
							<img src={Client9} alt="exampleImg" />
							<img src={Client10} alt="exampleImg" />					
							<img src={Client11} alt="exampleImg" />
							<img src={Client12} alt="exampleImg" />
						</div>

						<div className="sliderFrame sliderFour">
							<img src={Client13} alt="exampleImg" />
							<img src={Client14} alt="exampleImg" />					
							<img src={Client15} alt="exampleImg" />
							<img src={Client16} alt="exampleImg" />	
						</div>

						<div className="sliderFrame sliderFive">
							<img src={Client17} alt="exampleImg" />
							<img src={Client18} alt="exampleImg" />					
							<img src={Client19} alt="exampleImg" />
							<img src={Client20} alt="exampleImg" />	
						</div>

						<div className="sliderFrame sliderSix">
							<img src={Client21} alt="exampleImg" />
							<img src={Client22} alt="exampleImg" />					
							<img src={Client23} alt="exampleImg" />
							<img src={Client24} alt="exampleImg" />
						</div>

					</div>


				</div>


				<div className="arrows rightArrowDiv">

					<i onClick={this.moveSlideRight} className={this.state.rSlideCount === 5 ? this.state.hideArrow : this.state.rightArrow}></i>

				</div>

			</div>

		</div>



		)
	}
	}