import React, { Compomnent } from 'react';
import './beyondTheChair.css';
import '../App.css'
import Pat from '../assets/patcommunity.jpeg';
import PatAward from '../assets/pat.jpg';
import Bbanter from '../assets/barberBanter.png';
import NBA from '../assets/nba_players.jpeg';
import Fade from 'react-reveal/Fade';
import Fbook from '../assets/facebook.png';
import BookRelease from '../assets/bookRelease.jpeg';
import ReactPlayer from 'react-player'
import image1 from '../assets/patrick/file.jpeg';
import image2 from '../assets/patrick/file1.jpeg';
import image3 from '../assets/patrick/file3(1).jpeg';
import image4 from '../assets/patrick/file4(1).jpeg';
import image5 from '../assets/patrick/file5(1).jpeg';
import image6 from '../assets/patrick/file6(1).jpeg';
import image7 from '../assets/patrick/file7(1).jpeg';
import image8 from '../assets/patrick/file9(1).jpeg';
import banner from '../assets/bytbcmobile.jpeg';

const BeyondTheChair=()=>{

	return (

		<div className="btcContainer">

	<div className="beyondTheChair">



		</div>

		<div className="btcContent">

		<h1 className="bytcHeading">BEYOND THE BARBERS CHAIR</h1>


		<div className="btcText text-left">


					<div className="btcImg">

				<img src={PatAward} className="bytImg" alt="Patrick" />

			</div>

			<div className="btc-info">

			<span className="btcHeading">Master Barber Patrick Phipps</span>

			<p>Cut Above The Rest was established in 1991 by Master barber Patrick Anthony Phipps and has now been a successful business for over 24 years. Born in Forest Gate hospital to working class Jamaican Parents and raised in the East end of London, Patrick Phipps is a Celebrity in his own right - well known in the East End World (where he grew up) and all throughout London and the UK; he has been barbering for the last 28 years.</p>

			<p>He is a successful Entrepreneur, with his own barbering business, previous clothing line (www.funkkwest.com) and 2 book publications; My Little Book of Empowerment focusing on the power of positive thinking in alignment with African proverbs & Twisted lanes that discusses 4 different stories in 1 based around culture, time and gang crime, which was also made available for young people with teaching points and adapted into a short play for CLOUT Festival 2019. Patrick is also a fully qualified Lecturer in Barbering, a member of City & Guilds T-Levels Board and a member of the New Board. He is also part of the Newham Chamber of Commerce. Patrick has made short films such as “One in the Chamber” which is a high impact, intense and very touching short film that everyone can relate to discussing the reality and effects of gun crime.</p>

			<p>He is well known to sporting stars, celebrities, actors, singers and many in the Uk Hip-hop/Grime Entertainment Industry particularly as many of them grew up in Patricks barber chair, all of whom frequently visit Cut Above The Rest Barber studio, which also houses a tattoo parlour, Cut Above The Ink.</p>
				

			</div>

		</div>


			<div class="btcText text-left">



			<div className="btc-info text-right">

			<span className="btcHeading">Helping The Community</span>

			<p>In 2017, Patrick Phipps won Employer of the Year at Newham College’s Employer Links Awards. He was also awarded by the charity TROAN in Newham. Patrick said he was “humbled” by the recognition and was glad to be able to give opportunities to people in the community looking for a career, adding that “it was fantastic to get the recognition of the work we do and I’m very proud. It’s important to give youngsters a chance to get into a something at a young age so they can build for the future and stay out of trouble.”</p>

			<p>Patrick has trained many barbers who have either moved onto other barber shops or started up their own, he has always been a teacher. Patrick is also a Lecturer in Barbering at Newham College East Ham, where he teaches students how to cut hair, maintain their equipment, professionalism and hygiene.</p>

			<p>He has taken on apprentices and employees alike, who have flown the nest to start up their own businesses. Being that “The Barber Shop” is a place where people can come and be part of a community, Patrick often makes it his responsibility to support young people who may need his help or advise.</p>

			<p>Patrick and his team also hold charity events such as, providing the homeless with free haircuts and holding clothes for the homeless and bag events (providing people with bags of toiletries and warm clothes). In 2014, Patrick competed in the Miami Pro - UK Championships to raise money for Ghana via ADAP, he came 2nd in the over 44 category!</p>

			<p>Patrick has put on Family Fun days at Lister School where he used to be a student. He also held several barbering events “Barber Brawl” a competition with different heats and one winner, which also included entertainment on the night from numerous young people to involve the community. He was also a Mentor at Gainsborough and Cumberland School in East London, where he sat with young teens who needed a “Big Brother” to look up to.</p>							

			</div>
			

					<div className="btcImg">

				<img src={Pat} className="bytImg" alt="Patrick" />

			</div>		

		</div>	

		<div class="btcText text-left">


					<div className="btcImg">

				<img src={Bbanter} className="bytImg" alt="Barber Banter" />

			</div>

			<div className="btc-info">

			<span className="btcHeading">Barber Banter</span>

			<p>Barber Banter is an entertainment channel, that host’s weekly debates on YouTube, surround current events, old events and allows for a discussion to take place in the Barber Shop. This Project is quite original to the Barbering Industry and homes in on what really happens in a barber shop environment!</p>

			<p>We have come away from the days of sitting silently and by yourself starring at clients before you having their hair cut. We have opened the doors to create a safe space for constructive debate and exchange, bring the community together and tackling the gaps between different social groups.</p>

			<p>Whatever background, age, culture, gender, sexual preference or race you have your space with us to select the topics you want to make it a serious, sometimes funny and open debate.</p>

			<p>Keep an eye on the Barber Banter page for updates, castings and chances to submit your debate topic.</p>	

			</div>

		</div>

		<div className="youTubeList">

			<h3 className="ytHeading">PATRICK PHIPPS: HIGHLIGHT MOMENTS</h3>
			
				<div className="galleryContainer">

					<div className="highlightImg">

						<img src={image1} alt="Patrick Phipps" />

					</div>

					<div className="highlightImg">

						<img src={image2} alt="Patrick Phipps" />

					</div>

					<div className="highlightImg">
						<img src={image7} alt="Patrick Phipps" />
					</div>

					<div className="highlightImg">
						<img src={image4} alt="Patrick Phipps" />
					</div>																

				</div>

				<div className="galleryContainer">
					<div className="highlightImg">

						<img src={NBA} alt="Patrick Phipps" />

					</div>

					<div className="highlightImg">

						<img src={image3} alt="Patrick Phipps" />

					</div>

					<div className="highlightImg">
						<img src={image6} alt="Patrick Phipps" />
					</div>

					<div className="highlightImg">
						<img src={image8} alt="Patrick Phipps" />
					</div>		

				</div>

			</div>		

			<div className="youTubeList">

			<h3 className="ytHeading">CUT ABOVE THE REST: ONE IN THE CHAMBER (FULL FILM)</h3>
			
				<div className="videoContainer">

			<ReactPlayer className="videoDiv" width="100%" height="100%"  controls={true} url='https://www.youtube.com/watch?v=oBB1-xEFQL8' playing={false} />

				</div>

			</div>
		


		</div>

		</div>
	)
}

export default BeyondTheChair;