import React, { Component } from 'react';
import './Recruitment.css';
import './beyondTheChair.css';


const Recruitement=()=>{
	return (
		<div className="recContainer">
			
			<div className="recBanner">
				
			</div>

			<div className="joinIntro">

				<div className="join-text">
					<h1>Join Our Team</h1>
					<h3>Cut Above The Rest Recruitment</h3>

					<h3 className="jobTitle">JOB DESCRIPTION</h3>

					<p> Here at Cut Above the rest, we have been providing barbering services for over 25 years. We are looking for professional barbers with a minimum of 1-2 years in trade or a graduate equivalent.</p>

						<p>- Haircuts: both classic and modern including women and children
						<br/>- Beards: sculpting, shaping, grooming (cut throat shaving and hot towels not essential but desired)
						<br/>- Male grooming and extra treatments (not essential but desired)</p>

					<p>We welcome barbers from all races, ethnicities and cultures. Cut Above is situated in a highly diverse part of London and we pride ourselves in being able to reach all diversity.</p>

					<p><span className="boldFont">Working Pattern</span></p>
					<p>This role is a fulltime position; 35 hours over set days. Part-time hours may be available upon discussions only. Successful candidates will be required to attend a skills test and interview upon invitation. You may also be asked to bring a portfolio with you.</p>


				</div>

				<div className="join-positions">

					<h3>Job Spec</h3>
					<ul>
						<li><span className="boldFont">Company:</span><br/>Cut Above The Rest</li>
						<li><span className="boldFont">Location:</span><br/>East Ham, East London</li>
						<li><span className="boldFont">Salary:</span><br/>Self-Employed</li>
						<li><span className="boldFont">Rent:</span><br/>£150 p/w</li>
						<li><span className="boldFont">Hours:<br/></span>35 over set days (part-time negotiable)</li>								
					</ul>

				</div>

		</div>

		<div className="mobileRec">

				<h1>Join Our Team</h1>
				<h3>Cut Above The Rest Recruitement</h3>

					<h3 className="jobTitle">JOB DESCRIPTION</h3>

					<ul className="mobileJobSpec">
						<li>Company: Cut Above The Rest</li>
						<li>Location: East Ham, East London</li>
						<li>Salary: Self-Employed</li>
						<li>Rent: £150 p/w</li>
						<li>Hours: 35 over set days (part-time negotiable)</li>								
					</ul>


					<p> Here at Cut Above the rest, we have been providing barbering services for over 25 years. We are looking for professional barbers, minimum 1-2 years in trade or a graduate equivalent.</p>

						<p>Haircuts: both classic and modern including women and children
						<br/>Beards: sculpting, shaping, grooming (cut throat shaving and hot towels not essential but desired)
						<br/>Male grooming and extra treatments (not essential but desired)</p>

					<p>We welcome barbers from all races, ethnicities and cultures. Cut Above is situated in a highly diverse part of London and so we pride ourselves on being able to reach all diversity.</p>

					<p><span className="boldFont">Working Pattern:</span></p>
					<p>This role is a fulltime position; 35 hours over set days. Part-time hours may be available upon discussion only. Successful candidates will be required to attend a skills test and interview upon invitation. You may also be asked to bring a portfolio with you.</p>



		</div>

	</div>

		)
}

export default Recruitement;