import React, { Component } from 'react';
import { Link } from 'react-router';
import { BrowserRouter, Route, NavLink, Switch } from 'react-router-dom';
import Logo from '../assets/logo.jpg';
import MLogo from '../assets/mobileLogo.jpg';
import './desktopnav.css';

import HomeBanner from '../components/HomeBanner';
import WalkInServices from '../components/WalkInServices';
import WalkInServicesKids from '../components/WalkInServicesKids';
import BeyondTheChair from '../components/beyondTheChair';
import LookBook from '../components/LookBook';
import Tatoos from '../components/tatooPeircings';
import Recruitement from '../components/Recruitment';
import ContactUs from '../components/ContactUs';

const DesktopNav=(props)=>{
	return (
	<div className="desktopNavContainer">

		<div className={props.mobileNavClass}>

			<span className="closeMobileNav"></span>

			<ul className="mobileNavLinks">
				<li onClick={props.handleNavigation}>HOME</li>
				<li onClick={props.handleNavigation}>REGULAR SERVICES</li>
				<li onClick={props.handleNavigation}>CHILDRENS SERVICES</li>
				<li onClick={props.handleNavigation}>LOOK BOOK</li>	
				<li onClick={props.handleNavigation}>RECRUITMENT</li>														
				<li onClick={props.handleNavigation}>CONTACT</li>
			</ul>

		</div>

		<div className="mobileNav">
			<div className="mobileMenu">
				<i onClick={props.revealMobileNav} className={props.expandSymbol}></i> <i onClick={props.closeMobileNav} className={props.closeSymbol}></i> <span className="mobileMenuTab">MENU</span>		
			</div>	
		
		</div>

		<div className="desktopNavDiv">

			<div className="navLogo">
				<img src={Logo} alt="Logo" onClick={props.logoHome} />
			</div>

			<div className="navLinks">
				<ul>
					<li onClick={props.handleNavigation} className={props.activeLink === "HOME" ? "activeLink" : null}>HOME</li>
					<li onClick={props.handleNavigation} className={props.activeLink === "REGULAR SERVICES" ? "activeLink" : null}>REGULAR SERVICES</li>
					<li onClick={props.handleNavigation} className={props.activeLink === "CHILDRENS SERVICES" ? "activeLink" : null}>CHILDRENS SERVICES</li>			
					<li onClick={props.handleNavigation} className={props.activeLink === "LOOK BOOK" ? "activeLink" : null}>LOOK BOOK</li>		
					<li onClick={props.handleNavigation} className={props.activeLink === "CONTACT" ? "activeLink" : null}>CONTACT</li>		
				</ul>
			</div>

		</div>

	</div>	
		)

}

export default DesktopNav;