import React, { Component } from 'react';
import './WalkInServices.css';

const WalkInServices=()=>{
	return (
		<div className="walkInServicesTemplate">

			<div className="walkInservicesDiv">

				<h2>REGULAR SERVICES</h2>

				<p>Shape Up: £5.00</p>
				<p>Basic Cut: £10</p>
				<p>Comb Over: £12</p>				
				<p>High Top: £12</p>
				<p>Mohican: £10</p>	
				<p>Design/Patterns: From £15</p>
				<p>Afro Cut: From £12</p>
				<p>Scissors Cut: From £12</p>
				<p>Scissors Cut & Beard: £15</p>																		
				<p>Black Mask & Steam: £15</p>
				<p>Haircut & Shave: £15</p>		
				<p>Haircut & Beard: £15</p>
				<p>Black Spray: £2</p>
				<p>Sponge Twists: £2</p>	

			</div>

		</div>
	)
}

export default WalkInServices;