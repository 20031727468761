import React, {Component} from 'react';
import "./BasketPage.css";

const BasketItemList=(props)=>{
	return(
		<ul className="checkoutList">
			<li><img src={props.basketImg}/><br/>{props.basketName}<br/>
			<span onClick={props.removeItem} className="deleteItem">Remove Item</span></li>
			<li><span className="responsiveLabel">PRODUCT PRICE:</span> <br/> £{props.basketPrice}</li>
			<li><i onClick={props.decreaseQty} class="qty-change fas fa-minus"></i> {props.basketQty} <i onClick={props.increaseQty} class="qty-change fas fa-plus"></i></li>
			<li><span className="responsiveLabel">PRODUCT SUBTOTAL:</span><br/>£{Number(props.basketTotal).toFixed(2)}</li>
		</ul>
		)
}

export default BasketItemList;