import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';

//Components
import DesktopNav from './components/desktopnav';
import HomeBanner from './components/HomeBanner';
import Products from './components/Products';
import ProductTemplate from './components/ProductTemplate';
import BasketComponent from './components/BasketPage';
import BasketItemList from './components/BasketItemsComponent';
import BookService from './components/BookService';
import ResponsiveBarberProfile from './components/ResponsiveBarberProfile';
import MeetTheStaff from './components/MeetTheStaff';
import ContactUs from './components/ContactUs';
import InsideTheShop from './components/InsideTheShop';
import WalkInServices from './components/WalkInServices';
import PaymentComponent from './components/PaymentComponent';
import WalkInServicesKids from './components/WalkInServicesKids';
import BeyondTheChair from './components/beyondTheChair';
import LookBook from './components/LookBook';
import Tatoos from './components/tatooPeircings';
import Recruitement from './components/Recruitment';

//Hair product images
import HairProduct1 from './assets/hair1.jpg';
import HairProduct2 from './assets/hair2.jpg';
import HairProduct3 from './assets/hair3.jpg';
import HairProduct4 from './assets/hair4.jpg';
import HairProduct5 from './assets/hair5.jpg';
import HairProduct6 from './assets/hair6.jpg';
import HairProduct7 from './assets/hair7.jpg';
import HairProduct8 from './assets/hair8.jpg';
import HairProduct9 from './assets/hair9.jpg';

//Beard product images
import BeardProduct1 from './assets/beard1.jpg';
import BeardProduct2 from './assets/beard2.jpg';
import BeardProduct3 from './assets/beard3.jpg';
import BeardProduct4 from './assets/beard4.jpg';
import BeardProduct5 from './assets/beard5.jpg';
import BeardProduct6 from './assets/beard6.jpg';
import BeardProduct7 from './assets/beard7.jpg';

//Barbers
import Barber1 from './assets/barber1.jpg';
import Barber2 from './assets/barber2.jpg';
import Barber3 from './assets/barber3.jpg';
import Barber4 from './assets/barber4.jpg';
import Barber5 from './assets/barber5.jpg';
import Barber6 from './assets/barber6.jpg';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      basketQty: 0,
      activeLink: null,
      mNavStatus: "extendMobileNav defaultHide",
      slideMenu: false,
      basketPage: false,
      booking: false,
      bookingService: null,
      currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToRecruitment={this.goToRecruitment} goToTatoo={this.goToTatooServices} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      productCategory: null,
      currentProducts: [],
      subTotal: null,
      cardHolderName: null,
      cardHolderNumber: null,
      cardHolderMonth: null, 
      cardHolderYear: null,
      services: {
        hairTrim: {
          name: "Standard Hair Trim",
          description: "Our most popular barber service! Should you require a contemporary or classic haircut this option is for you. This 30-minute barbering service includes a thorough consultation, hair wash/condition, providing professional styling advice using premium Ace Barbers bespoke men’s styling products. All services are concluded with an optional neck shave to leave a clean, natural finish.",
          price: "£35.99 via booking",
          duration: "30 minutes"
        },
        beardTrim: {
          name: "Standard Beard Trim",
          description: "Is your beard in need of some much needed facial hair maintenance? Our professional barbers will consult, advise and help you achieve the desired look with our specialist beard knowledge (no open blade used).",
          price: "£32.99 via booking",
          duration: "15 minutes",
        },
        hairBeard: {
          name: "Hair & Beard Style or Trim",
          description: "This service combines both of our hair and beard expertise to deliver an overall fantastic and accurate finish to match the clients needs and desire. Beard can be styled to any shape desired whilst hair can be modified to compliment the beard's length, design or new shape, and vise versa from our professional barbers.",
          price: "£49.99 via booking",
          duration: "40 minutes",
        },
        eyebrowThreading: {
          name: "Eyebrow Threading",
          description: "An essential element of a modern man’s look is facial hair. But “facial hair” does not only concern the beard, moustache or sideburns. The eyebrows are a crucial part of it too. Messy hair in that region can result in an unkempt look. Ace Barbers has a team of skilled barbers who can take care of the eyebrow area. Our eyebrow stylists use one of the gentlest techniques to control unwanted hair in the area.",
          price: "£14.99",
          duration: "10 minutes"
        }

      }, 
      products: {
        hair: [
          {
           id: 1, 
           name: "Gatsby Styling Wax", 
           summary: "Quality wax to create diverse hairstyles and volume with a strong styling ability.",
           price: 7.99,
           qty: 1,
           img: HairProduct1
          },
          {
            id: 2,
            name: "ManCave Caffeine Shampoo",
            summary: "Packed with Caffeine, L-arginine, Shea Butter, Panthenol and 8 Essential Oils.",
            price: 9.85,
            qty: 1,            
            img: HairProduct2            
          },
          {
            id: 3,
            name: "Damage Repairing & Rehydrating Conditioner",
            summary: "A nourishing conditioner to restore hair manageability and elasticity.",
            price: 13.99,
            qty: 1,
            img: HairProduct3
          },
          {
            id: 4,
            name: "UrbanGabru Hair Serum with Almond oil",
            summary: "Boosts shine and creates a nourishing protective barrier between your hair and high heat styling tools.",
            price: 6.99,
            qty: 1, 
            img: HairProduct4              
          },
          {
            id: 5,
            name: "Texturising hair lotion",
            summary: "Providing shine, softening and moisture to natural and permanently waved hair.",
            price: 15.99,
            qty: 1,             
            img: HairProduct5   
          },
          {
            id: 6,
            name: "Baxter of California: Clay Pomade",
            summary: "This pomade blends natural Clay and Beeswax for unrivalled hold and texture.",
            price: 11.99,
            qty: 1,             
            img: HairProduct6            
          },
          {
            id: 7,
            name: "Mister Pompadour texture powder",
            summary: "Adds volume and texture while providing a high hold and completely natural matte finish.",
            price: 14.99,
            qty: 1,
            img: HairProduct7           
          },
          {
            id: 8,
            name: "Redken Brews Silver Shampoo",
            summary: "Effectively cleanses white and grey hair to leave you feeling revitalised around the clock.",
            price: 18.99,
            qty: 1,
             img: HairProduct8          
          },
          {
            id: 9,
            name: "Hanz De Fuko Style Lock Hairspray",
            summary: "Supreme hold hairspray which with peptides and natural proteins to condition with each application.",
            price: 12.99,
            qty: 1,
            img: HairProduct9             
          }
        ],

        beard: [
          {
            id: 10,
            name: "Truefitt & Hill shaving cream",
            summary: "A glycerin-based shaving cream created to achieve an incredibly close yet comfortable shave.",
            price: 8.99,
            qty: 1,
            img: BeardProduct1              
          },
          {
            id:11,
            name: "The Beard Legacy: Bird Oil",
            summary: "Restore softness and shine to your beard, with 100% natural and organic ingrediants.",
            price: 17.99,
            qty: 1,            
            img: BeardProduct2                   
          },
          {
            id: 12,
            name: "Shaving Kit",
            summary: "This kit is the core basics and essentials to manage, cut and maintain your beard's style.",
            price: 9.99,
            qty: 1,
            img: BeardProduct3                   
          },
          {
            id: 13,
            name: "Beekman SHave Bar",
            summary: "Perfect alternative to shaving foam, with natural ingrediants shea butter and goat milk.",
            price: 3.99,
            qty: 1,
            img: BeardProduct4                   
          },
          {
            id: 14,
            name: "Maracuja Oil & Shea Butter",
            summary: "Soften hair and ease out knots to look good and smell great!",
            price: 6.99,
            qty: 1,
            img: BeardProduct5                   
          },
          {
            id: 15,
            name: "Dark Stag Superior Beard Comb",
            summary: "Keep your beard managed and in top style with this retro designed comb",
            price: 3.99,
            qty: 1,
            img: BeardProduct6                   
          },
          {
            id: 16,
            name: "Fiber Hair Wax",
            summary: "Fantastic, non-wet-look hold for beards which require styling and shaping",
            price: 9.99,
            qty: 1,
            img: BeardProduct7                   
          }
        ]
      },
    }
  }

  goToRecruitment=()=>{
    window.scroll(0,0);      
    this.setState({
      currentPage: <Recruitement />,
      booking: false      
    })

  }

  goToAppBooking=()=>{
      this.setState({
      currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToRecruitment={this.goToRecruitment} goToTatoo={this.goToTatooServices} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      productCategory: null,
      activeLink: "home",
      booking: null                 
      });    
  }

  goToTatooServices=()=>{
    window.scroll(0,0);      
    this.setState({
      currentPage: <Tatoos />,
      activeLink: null,      
      booking: false      
    })
  }

  hairTrimBooking=()=>{
      this.setState({
        booking: true,
        bookingService: "hairTrim",
        currentPage: null
      })
  }

   beardTrimBooking=()=>{
      this.setState({
        booking: true,
        bookingService: "beardTrim",
        currentPage: null        
      })
  }

  hairBeardBooking=()=>{
      this.setState({
        booking: true,
        bookingService: "hairBeard",
        currentPage: null      
      })
  }

  eyebrowThreading=()=>{
      this.setState({
        booking: true,
        bookingService: "eyebrowThreading",
        currentPage: null      
      })
  }  
 
  goToBooking=()=>{
    this.setState({
      currentPage: <BookService />,
      booking: false
    })
  }

  goToTheLounge=()=>{
    window.scroll(0,0);       
    this.setState({
      currentPage: <BeyondTheChair />,
      activeLink: null,
      booking: false      
    })
  }

  goToManagementTeam=()=>{
    window.scroll(0,0);
    this.setState({
      currentPage: <WalkInServicesKids />,
      activeLink: "CHILDRENS SERVICES",      
      booking: false     
    })
  }


  goToWalkInServices=()=>{
    window.scroll(0,0);    
    this.setState({
      currentPage: <WalkInServices />,
      activeLink: "REGULAR SERVICES",         
      booking: false
    })
  }

  loadHomePage=()=>{
        window.scroll(0,0);
    this.setState({
      currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToRecruitment={this.goToRecruitment} goToTatoo={this.goToTatooServices} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      activeLink: null,
      booking: false,
       mNavStatus: "extendMobileNav defaultHide hideContent"      

    })
  }

  openAcademyLink=()=>{
        window.open("http://cutabovebarberacademy.co.uk/");
  }

    openTheCreativePresence=()=>{
    window.open("http://thecreativepresence.co.uk/");
  }

  logoHomeNav=()=>{
   window.scroll(0,0);  
      this.setState({
        currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToRecruitment={this.goToRecruitment} goToTatoo={this.goToTatooServices} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      productCategory: null,
      activeLink: "HOME",
      booking: null,
       mNavStatus: "extendMobileNav defaultHide hideContent"                       
      });      
  }

  handleNavigation=(page)=>{
    
      switch (page) {

      case "HOME":
         window.scroll(0,0);     
      this.setState({
        currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToRecruitment={this.goToRecruitment} goToTatoo={this.goToTatooServices} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      productCategory: null,
      activeLink: "HOME",
      booking: null,
       mNavStatus: "extendMobileNav defaultHide hideContent"                       
      });
      break;

      case "RECRUITMENT":
         window.scroll(0,0);     
      this.setState({
        currentPage: <Recruitement />,
      productCategory: null,
      activeLink: null,
      booking: null,
       mNavStatus: "extendMobileNav defaultHide hideContent"                       
      });
      break;      


      case "REGULAR SERVICES":
            window.scroll(0,0);  
      this.setState({
        currentPage: <WalkInServices />,
      basketPage: false,
      productCategory: null,
      activeLink: "REGULAR SERVICES",
      booking: null,
    mNavStatus: "extendMobileNav defaultHide hideContent"                   
      });
      break;

      case "LOOK BOOK":
            window.scroll(0,0);  
      this.setState({
        currentPage: <LookBook />,
      basketPage: false,
      productCategory: null,
      activeLink: "LOOK BOOK",
      booking: null,
    mNavStatus: "extendMobileNav defaultHide hideContent"                   
      });
      break;      

      case "CHILDRENS SERVICES":
            window.scroll(0,0);  
      this.setState({
        currentPage: <WalkInServicesKids />,
      basketPage: false,
      productCategory: null,
      activeLink: "CHILDRENS SERVICES",
      booking: null,
      mNavStatus: "extendMobileNav defaultHide hideContent"                  
      })
      break;

      case "CONTACT":
          window.scroll(0,0);   
      this.setState({
      currentPage: <ContactUs />,
      basketPage: false,
      productCategory: null,
      activeLink: "CONTACT",
      booking: null,
      mNavStatus: "extendMobileNav defaultHide hideContent"                  
      })
      break;      

    } 

  }

  //Display the total price of basket items as subtotal
   totalPrice=()=>{
      let currentProducts = [...this.state.currentProducts];
      if (currentProducts.length < 1){
        return false;
      }
      let subtotal = [];
      for (let i = 0; i < currentProducts.length; i++){
        subtotal.push(currentProducts[i].qty * currentProducts[i].price);
      }
      let totalPrice = subtotal.reduce((a,b)=> {
        return a+b;
      })
      this.setState({
          subtotal: totalPrice
      })
    }

  //Delete the item completely when the user clicks on remove item
  handleItemDelete=(index)=>{
    let existingProducts = [...this.state.currentProducts];
    let targetedProductArr = existingProducts.splice(index,1);
    if (existingProducts.length === 0){
      this.setState({
       currentProducts: [],
       basketQty: 0,
       currentPage: <HomeBanner />  
      })
    } else {
    let subtotal = [];
      for (let i = 0; i < existingProducts.length; i++){
        subtotal.push(existingProducts[i].qty * existingProducts[i].price);
      }
      let totalPrice = subtotal.reduce((a,b)=> {
        return a+b;
      })
    this.setState({
        currentProducts: existingProducts,
        basketQty: this.state.basketQty-targetedProductArr[0].qty,
        subtotal: totalPrice  
    })
    }
  }

  //Increase the subtotal when user clicks on increase qty tab
  handleQtyIncrease=(index)=>{
    let existingProducts = [...this.state.currentProducts];
    let targetedProductArr = existingProducts.splice(index,1);
    let targetedProductObj = Object.assign({},targetedProductArr[0]);
    targetedProductObj.qty++;
    existingProducts.splice(index,0,targetedProductObj);
    let subtotal = [];
    for (let i = 0; i < existingProducts.length; i++){
        subtotal.push(existingProducts[i].qty * existingProducts[i].price);
      }    
      let totalPrice = subtotal.reduce((a,b)=> {
        return a+b;
      })  
    this.setState({
      currentProducts: existingProducts,
      basketQty: this.state.basketQty+1,
      subtotal: totalPrice
    })

  } 



  //Decrease the subtotal when user clicks on decrease qty tab
  handleQtyDecrease=(index)=>{
    let existingProducts = [...this.state.currentProducts];
    let targetedProductArr = existingProducts.splice(index,1);
    let targetedProductObj = Object.assign({},targetedProductArr[0]);
    if (targetedProductObj.qty === 1) {
      return false;
    }
    targetedProductObj.qty--;
    existingProducts.splice(index,0,targetedProductObj);
    let subtotal = [];
    for (let i = 0; i < existingProducts.length; i++){
        subtotal.push(existingProducts[i].qty * existingProducts[i].price);
      }    
      let totalPrice = subtotal.reduce((a,b)=> {
        return a+b;
      })        
    this.setState({
      currentProducts: existingProducts,
      basketQty: this.state.basketQty-1,
      subtotal: totalPrice
    })
    
  }

  showMobileNav=()=>{
    this.setState({
      mNavStatus: "extendMobileNav defaultHide showMobileNav"
    })
  }

  hideMobileNav=()=>{
    this.setState({
    mNavStatus: "extendMobileNav defaultHide hideContent"
    })
  }

  //Load the basket page
  handleBasketPage=()=>{
    let currentProducts = [...this.state.currentProducts];   
    if (currentProducts.length < 1) {
    alert("There are no items in your basket");
    return false;
    }
    this.setState({
      currentPage: null,
      basketPage: true,
      productCategory: null,
      mNavStatus: "extendMobileNav defaultHide hideContent"      
    })
    this.totalPrice();
  }

  //Display beard products 
  handleBeardProducts=()=>{
    this.setState({
      currentPage: null,
      productCategory: "beard",
    })
  }


  //Dislay hair prducts
  handleHairProducts=()=>{
    this.setState({
      currentPage: null,
      productCategory: "hair",
    })
  }

  handleCardName=(input)=>{
    this.setState({
      cardHolderName: input.value
    })
  }

  handleCardNumber=(input)=>{
    this.setState({
      cardHolderNumber: input.value
    })
  }

  handleCardMonth=(input)=>{
    this.setState({
      cardHolderMonth: input.value
    })      
  }

  handleCardYear=(input)=>{
    this.setState({
      cardHolderYear: input.value
    })
  }

  completePayment=(e)=>{
    e.preventDefault();
    let testLetter = /[a-z]/gi;
    let testNumber = /[0-9]/g;
    let testMonth = /[0-9]{2}/;
    let testYear = /[0-9]{4}/;    
    //CHECK TO SEE IF ALL FIELDS HAVE BEEN FILLED
    if (!this.state.cardHolderName || !this.state.cardHolderNumber || !this.state.cardHolderMonth || !this.state.cardHolderYear ) {
      alert("Please fill in all fields!");
      return false;
    }

    if (this.state.cardHolderNumber.search(testLetter) > -1){
        alert("Please enter a valid card number");
        return false;
    }

    if (this.state.cardHolderName.search(testNumber) > -1){
        alert("Please enter a valid card name");
        return false;
    } 

    if (!testMonth.test(this.state.cardHolderMonth)){
        alert("Please enter a valid month");
        return false;
    } 

    if (!testYear.test(this.state.cardHolderYear)){
        alert("Please enter a valid year");
        return false;
    }     

    alert("Thank you for placing your order with Ace Barbers!");
    this.setState({
      currentPage: <HomeBanner openCreativePresence={this.openTheCreativePresence} goToWalkInServices={this.goToWalkInServices} goToBookNow={this.goToAppBooking} goToTheLounge={this.goToTheLounge} goToManagementTeam={this.goToManagementTeam}/>,
      basketQty: 0,
      currentProducts: []
    })
  }

  loadCheckoutPage=()=>{
    this.setState({
      currentPage: <PaymentComponent handleCardYear={(e)=>{this.handleCardYear(e.target)}} handleCardMonth={(e)=> this.handleCardMonth(e.target)} handleCardNumber={(e)=> this.handleCardNumber(e.target)} handleCardName={(e)=> this.handleCardName(e.target)} cardName={this.state.cardHolderName} completePayment={this.completePayment} totalPayment={this.state.subtotal}/>,
      basketPage: false,
      activeLink: null     
    })
  }

  //Add products which user has selected to the state
  addToBasket=(value,index)=>{
    console.log(value.name);
    let prodObj = Object.assign({},value);
    //If this object already exists within the current products array, I am simply going to splice out, add to its
    //qty property and then splice it back into the array, before updating state
    let existingProducts = [...this.state.currentProducts];
    let validateIndex = existingProducts.findIndex(element=> element.name === value.name);
    if (validateIndex < 0){
      existingProducts.push(prodObj);
      this.setState({
        currentProducts: existingProducts,
        basketQty: this.state.basketQty+1
      })
    } else {
      let repeatedProduct = existingProducts.splice(validateIndex,1);
      let productObj = Object.assign({}, repeatedProduct[0]);
      productObj.qty++;
      existingProducts.splice(validateIndex,0,productObj);
      this.setState({
        currentProducts: existingProducts,
        basketQty: this.state.basketQty+1        
      })
    }
  }

  backToTop=()=>{
    window.scroll(0,0);
  }

  render() {

    let bookingProcess;
    let selectedService;

    if (this.state.bookingService === "hairTrim"){
      selectedService = "Hair Trim at £35.99";
    }

    if (this.state.bookingService === "beardTrim"){
      selectedService = "Beard Trim at £32.99";
    }

    if (this.state.bookingService === "hairBeard"){
      selectedService = "Hair and Beard at £32.99";
    }    

    if (this.state.bookingService === "eyebrowThreading"){
      selectedService = "Eyebrow Threading at £14.99";
    }    


    if (!this.state.bookingProcess) {
      bookingProcess = null;
    }

    let basketProducts = null;
    let listProducts = null;
    let mainContent = "mainContent";
    let closeSymbol = "hideContent";
    let expandSymbol = "fas fa-bars mm-tab";

    if (this.state.mNavStatus === "extendMobileNav defaultHide showMobileNav"){
      mainContent = "hideContent";
      expandSymbol = null;
      closeSymbol = "fas fa-times mm-tab";
    }

    if (this.state.booking){
      bookingProcess = (
        <div>
          <BookService name={this.state.services[this.state.bookingService].name} description={this.state.services[this.state.bookingService].description} price={this.state.services[this.state.bookingService].price} duration={this.state.services[this.state.bookingService].duration} selectedService={selectedService}/>
        </div>
        )
    }

    if (this.state.productCategory === "hair"){
      listProducts = (
              <div className="ListProducts">
            {this.state.products.hair.map((value,index)=> <ProductTemplate
              key={value.id}
              productImage={value.img} 
              productName={value.name}
              productPrice={value.price}
              addItem={()=> this.addToBasket(value,index)}
              >{value.summary}</ProductTemplate>)
          }
              </div>
  
        )
    }

    if (this.state.productCategory === "beard"){
      listProducts = (
          <div className="ListProducts">
            {this.state.products.beard.map((value,index) => <ProductTemplate
              key={value.id}
              productImage={value.img} 
              productName={value.name}
              productPrice={value.price}
              addItem={()=> this.addToBasket(value,index)}
              >{value.summary}</ProductTemplate>)}
          </div>
        )
    }

    if (this.state.basketPage){
      basketProducts = (
          <div>
            <BasketComponent loadCheckoutPage={this.loadCheckoutPage} totalPrice={this.state.subtotal}>
            {this.state.currentProducts.map((value,index)=>
            <BasketItemList 
            key={value.id}
            removeItem={()=>this.handleItemDelete(index)} 
            basketImg={value.img} 
            basketName={value.name} 
            basketPrice={value.price} 
            increaseQty={()=>this.handleQtyIncrease(index)} 
            decreaseQty={()=> this.handleQtyDecrease(index)} 
            basketQty={value.qty} 
            basketTotal={value.qty * value.price}/>)}
            </BasketComponent>
          </div>
        )
    }

    return (
        <div className="App">
            <DesktopNav logoHome={this.logoHomeNav} openAcademy={this.openAcademyLink} activeLink={this.state.activeLink} revealMobileNav={this.showMobileNav} mobileNavClass={this.state.mNavStatus} closeMobileNav={this.hideMobileNav} loadBasketItems={this.handleBasketPage} expandSymbol={expandSymbol} closeSymbol={closeSymbol} basketQty={this.state.basketQty} goHomePage={this.loadHomePage} handleNavigation={(e)=> this.handleNavigation(e.target.innerText)}/>
              <div className={mainContent}>
                {this.state.currentPage}
                {listProducts}
                {bookingProcess}
                {basketProducts}
              </div>           
        </div>
    );
  }
}

export default App;
