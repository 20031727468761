import React, { Component } from 'react';
import './BookService.css';
import Michael from '../assets/barber1.jpg';
import Daniel from '../assets/barber2.jpg';
import Ryan from '../assets/barber3.jpg';
import Hector from '../assets/barber4.jpg';
import Baraka from '../assets/barber5.jpg';
import Demitry from '../assets/barber6.jpg';

const ResponsiveBarberProfile=(props)=>{
	return (
		<div className="mobileBarberSection">

			<h1>SELECT YOUR BARBER</h1>	

			<div className={props.barberSlideStatus}>

				<div className="mobileBarberPick">

					<div className="barberFlex">
						
						<div className="barberTemplate">
							<img id="michael" onClick={props.revealBarberChoice} src={Michael}/>
							<span className="barberProfileNameTag">Michael</span>
							<span className="barberProfileSkillsTag">Beard Design</span>
						</div>

						<div className="barberTemplate">
							<img id="daniel" onClick={props.revealBarberChoice} src={Daniel}/>
							<span className="barberProfileNameTag">Daniel</span>
							<span className="barberProfileSkillsTag">Hair Styling</span>							
						</div>

					</div>

					<div className="barberFlex">
						<div className="barberTemplate">
							<img id="ryan" onClick={props.revealBarberChoice} src={Ryan}/>
							<span className="barberProfileNameTag">Ryan</span>
							<span className="barberProfileSkillsTag">Beard Shaping</span>
						</div>

						<div className="barberTemplate">
							<img id="hector" onClick={props.revealBarberChoice} src={Hector}/>
							<span className="barberProfileNameTag">Hector</span>
							<span className="barberProfileSkillsTag">Shape Up</span>							
						</div>
					</div>

					<div className="barberFlex">
						<div className="barberTemplate">
							<img id="baraka" onClick={props.revealBarberChoice} src={Baraka}/>
							<span className="barberProfileNameTag">Baraka</span>
							<span className="barberProfileSkillsTag">Hair Styling</span>
						</div>

						<div className="barberTemplate">
							<img id="demitry" onClick={props.revealBarberChoice} src={Demitry}/>
							<span className="barberProfileNameTag">Demitry</span>
							<span className="barberProfileSkillsTag">Trimming</span>							
						</div>
					</div>										
																										

				</div>

				<div className="mobileBarberProfile">

					<div className={props.showMichael}>

						<img src={Michael} alt="Barber"/>

						<h4>Name: Michael Hensey</h4>
						<h4>Speciality: Beard Design, Trims & Fades</h4>
						<h4>Experience: 9 Years</h4>

						<p>Michael is one of Ace Barbers most senior, popular and experienced memebrs. He has worked in highly successfull hairdressers from across the world and has built up a valuable amount of experience, as well as an outstanding reputation with diverse styling techniques and styles.</p>

						<button onClick={props.selectMichael}>CHOOSE THIS BARBER</button>					
						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>														

					</div>

					<div className={props.showDaniel}>

						<img src={Daniel} alt="Barber"/>

						<h4>Name: Daniel Hensworth</h4>
						<h4>Speciality: Hair Styling & Shape Up</h4>
						<h4>Experience: 12 Years</h4>

						<p>Daniel's talents as a barber are a benchmark to strive towards. He has worked on many types of hairs yet has always delivered outstanding and accurate outcomes when it comes to customers requests or recommendations - weather completely changing the style or simply imporving it.</p>

						<button onClick={props.selectDaniel}>CHOOSE THIS BARBER</button>
						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>															

					</div>	

					<div className={props.showRyan}>

						<img src={Ryan} alt="Barber"/>

						<h4>Name: Ryan Pitt</h4>
						<h4>Speciality: Hair Styling & Beard Shaping</h4>
						<h4>Experience: 6 Years</h4>

						<p>Ryan is known for using advanced and creative methods to deliver immaculate finishes to beards and hair styling. He is very popular amongst customers who like to go for that unique look and continues proving that his methods of cuts and trims are some of the most effective and brilliant within the industry.</p>

						<button onClick={props.selectRyan}>CHOOSE THIS BARBER</button>

						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>												

					</div>		

					<div className={props.showHector}>

						<img src={Hector} alt="Barber"/>

						<h4>Name: Hector Rodrigo</h4>
						<h4>Speciality: Shape Up, Beards & Fades</h4>
						<h4>Experience: 15 Years</h4>

						<p>Hector is one of Ace Barbers most requested hairdressers for a reason - he continues elevating his game through continuesly adding new techniques to his ways and consequently delivers the most trendy and slickest of haircuts and beard shaping. A true natural when it comes to being a barber.</p>

						<button onClick={props.selectHector}>CHOOSE THIS BARBER</button>

						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>									

					</div>

					<div className={props.showBaraka}>

						<img src={Baraka} alt="Barber"/>

						<h4>Name: Baraka Muntasir</h4>
						<h4>Hair Styling, Beard Shaping & Trims</h4>
						<h4>Experience: 5 Years</h4>

						<p>Baraka is truly multi-talented when it comes to working as a barber. He is known for being able to adjust any hairstyle or beard to compliment the customers physical traits, and can deliver the most complex designs for beard and hair trimming with ease and precision.</p>

						<button onClick={props.selectBaraka}>CHOOSE THIS BARBER</button>
						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>										

					</div>

					<div className={props.showDemitry}>

						<img src={Demitry} alt="Barber"/>

						<h4>Name: Demitry Pirlo</h4>
						<h4>Hair Styling & Trimming</h4>
						<h4>Experience: 11 Years</h4>

						<p>Demitry is a barber who brings his own unique yet highly effective methods of trimming and styling hairs. He is highly talented and admired for his ability to deliver sleek finishes with scissors or clippers. He is a versatile barber who can deliver with any request and is known for his creative ability.</p>

						<button onClick={props.selectDimitry}>CHOOSE THIS BARBER</button>
						<div className="navBack" onClick={props.navigateBack}><i class="fas fa-long-arrow-alt-left"></i> GO BACK</div>					

					</div>																					


				</div>

			</div>

		</div>
		)
}





export default ResponsiveBarberProfile;