import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import './LookBook.css';
import Wallpaper from '../assets/tatoo/tatooWallpaper.jpg';

import Client1 from '../assets/tatoo/client1a.jpeg';
import Client2 from '../assets/tatoo/client2a.jpeg';
import Client3 from '../assets/tatoo/client3a.jpeg';
import Client4 from '../assets/tatoo/client4a.jpeg';
import Client5 from '../assets/tatoo/client5a.jpeg';
import Client6 from '../assets/tatoo/client6a.jpeg';
import Client7 from '../assets/tatoo/client7a.jpeg';
import Client8 from '../assets/tatoo/client8a.jpeg';
import Client9 from '../assets/tatoo/client9a.jpeg';
import Client10 from '../assets/tatoo/client10a.jpeg';
import Client11 from '../assets/tatoo/client11.jpeg';
import Client12 from '../assets/tatoo/client12.jpeg';
import Client13 from '../assets/tatoo/tclient1_tn.jpeg';
import Client14 from '../assets/tatoo/tclient2_tn.jpeg';
import Client15 from '../assets/tatoo/tclient3_tn.jpeg';
import Client16 from '../assets/tatoo/tclient4_tn.jpeg';
import Client17 from '../assets/tatoo/tclient5_tn.jpeg';
import Client18 from '../assets/tatoo/tclient6_tn.jpeg';
import Client19 from '../assets/tatoo/tclient7_tn.jpeg';
import Client20 from '../assets/tatoo/tclient8_tn.jpeg';
import Client21 from '../assets/tatoo/tclient9_tn.jpeg';
import Client22 from '../assets/tatoo/tclient10_tn.jpeg';
import Client23 from '../assets/tatoo/tclient11_tn.jpeg';
import Client24 from '../assets/tatoo/tclient12_tn.jpeg';
import Client25 from '../assets/tatoo/tclient13_tn.jpeg';
import Client26 from '../assets/tatoo/tclient14_tn.jpeg';
import Client27 from '../assets/tatoo/tclient15_tn.jpeg';
import Client28 from '../assets/tatoo/tclient16_tn.jpeg';
import Client29 from '../assets/tatoo/tclient17_tn.jpeg';
import Client30 from '../assets/tatoo/tclient18_tn.jpeg';
import Client31 from '../assets/tatoo/tclient19_tn.jpeg';
import Client32 from '../assets/tatoo/tclient20_tn.jpeg';
import Client33 from '../assets/tatoo/tclient21_tn.jpeg';
import Client34 from '../assets/tatoo/tclient22_tn.jpeg';
import Client35 from '../assets/tatoo/tclient23_tn.jpeg';
import Client36 from '../assets/tatoo/tclient24_tn.jpeg';
import Client37 from '../assets/tatoo/tclient25_tn.jpeg';

export default class Tatoos extends Component {
	constructor(props){
		super(props);
		this.state = {
			currentPimg: null,
			showMainImg: "hideImgContainer",
			showBanner: "tatooBanner",
			bigImage: "imgContainer",
			showPImgContainer: "hideContent",
			showPGallery: "pRow",
			pIntro: "lookBookIntro",
			tIntro: "lookBookIntro",
			showTGallery: "pRow"

		}

	}	

	loadPImg=(img)=>{	
		this.setState({
			currentPimg: img,
			showMainImg: "showImgContainer",			
			showBanner: "hideVisiblity",
			showPImgContainer: true,
			showPGallery: "hideContent",
			showTGallery: "hideContent",
			pIntro: "hideContent",
			tIntro: "hideContent"
		
		})
         window.scroll(0,0);  			
	}

	closePImg=()=>{
		this.setState({
			currentImg: null,
			showMainImg: "hideImgContainer",			
			showBanner: "tatooBanner",			
			showPImgContainer: "hideContent",
			showPGallery: "pRow",
			showTGallery: "pRow",
			pIntro: "lookBookIntro",
			tIntro: "lookBookIntro"			
		})
	}

	render(){

		return (

		<div className="tpMainContainer">

			<div>

			<div className={this.state.showBanner}>

			</div>

			<div className="pGallery">

			<div className={this.state.pIntro}>

				<h1>CUT ABOVE INK & STEEL<br/>PIERCING SERVICE</h1>

				<p>Cut above ink and steel can meet many different types of requests from customers. The gallery below is an example of some of the piercings we can offer. If you do not see what you require in the gallery, please feel free to contact us and ask for Dean, who is our head of piercing and tatoo department.</p>

				<p>Please note: Customers must be 18+ or be escorted by a gaurdian with an ID in order to undergo our piercing service.</p>	


				<div className="piercingPriceList">

					<div className="pplHeader">
						<div className="pplServiceType">
							<span className="pplHeadSection">PIERCING TYPE</span>
						</div>

						<div className="pplPrice">
							<span className="pplHeadSection">SERVICE COST</span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Naval</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25</span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Dermal Anchors</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£30 (2 for £50)</span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Tregus</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£30</span>
						</div>
					</div>	

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Nose</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£20 (Needle) </span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Tongue</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25</span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Eyebrow</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£20</span>
						</div>
					</div>		

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Lip</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£20</span>
						</div>
					</div>																												
					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Conch</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25</span>
						</div>
					</div>	

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Rock & Daith</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25</span>
						</div>
					</div>												

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Scaffold</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25</span>
						</div>
					</div>	

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Surface</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£30 (£30 Vertical)</span>
						</div>
					</div>

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Septum</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£30</span>
						</div>
					</div>	

					<div className="listContainer">
						<div className="pplTypeList">
							<span className="tableText">Nipples</span>							
						</div>

						<div className="pplCostList">
							<span className="tableText">£25 (2 for £40)</span>
						</div>
					</div>																


				</div>

				{/*<h3>PRICING</h3>	

				<span className="pPricing">Naval: £25</span>
				<span className="pPricing">Dermal Anchors: £30 (2 x £50)</span>
				<span className="pPricing">Tregus: £30</span>
				<span className="pPricing">Nose (Needle): £20</span>

				<span className="pPricing">Tongue: £25</span>
				<span className="pPricing">Eyebrow: £20 (2 for £50)</span>
				<span className="pPricing">Lip: £20 (Vertical: £30)</span>
				<span className="pPricing">Conch: £25</span>

				<span className="pPricing">Rook & Daith: £25</span>
				<span className="pPricing">Scaffold: £25</span>
				<span className="pPricing">Surface: £30 (Vertical: £30)</span>
				<span className="pPricing">Septum: £30</span>
				<span className="pPricing">Nipples: £25 (2 for £40)</span>*/}																		
			</div>

					<div className={this.state.showMainImg}>

					<div className={this.state.showPImgContainer}>
						<img className="selectedImg" src={this.state.currentPimg} alt="Client Image" />
						<span onClick={this.closePImg} className="closeImg">Close Window</span>
					</div>

					</div>

					<div className={this.state.showPGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client1} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client1} alt="Piercing Client" />
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client2} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client2} alt="Piercing Client" />
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client3} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client3} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client4} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client4} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client5} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client5} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client6} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client6} alt="Piercing Client" />							
						</div>

					</div>

					<div className={this.state.showPGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client7} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client7} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client8} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client8} alt="Piercing Client" />						
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client9} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client9} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client10} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client10} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client11} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client11} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client12} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client12} alt="Piercing Client" />							
						</div>

					</div>					

				</div>

			<div className="pGallery">

			<div className={this.state.tIntro}>

				<h1>CUT ABOVE INK & STEEL<br/>TATOO SERVICE</h1>

				<p>From flash designs to custom designs, to free-hand designs. If you require your own design, please pop in to our store where we can tailor to your needs prior to your appointment.<br/>Walk-ins (whilst the parlour is quiet) and bookings available.</p>

				<p>Please note: Customers must be 18+ or be escorted by a gaurdian with an ID in order to undergo our piercing service.</p>	

			</div>

					<div className={this.state.showTGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client13} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client13} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client14} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client14} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client15} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client15} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client16} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client16} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client17} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client17} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client18} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client18} alt="Piercing Client" />						
						</div>

					</div>

					<div className={this.state.showTGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client19} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client19} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client20} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client20} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client21} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client21} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client22} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client22} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client23} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client23} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client24} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client24} alt="Piercing Client" />
						</div>

					</div>	

					<div className={this.state.showTGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client25} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client25} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client26} alt="Piercing Client" />
					<img className="mobileTPImg" src={Client26} alt="Piercing Client" />			
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client27} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client27} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client28} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client28} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client29} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client29} alt="Piercing Client" />								
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client30} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client30} alt="Piercing Client" />								
						</div>

					</div>		

					<div className={this.state.showTGallery}>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client31} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client31} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client32} alt="Piercing Client" />
								<img className="mobileTPImg" src={Client32} alt="Piercing Client" />							
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client33} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client33} alt="Piercing Client" />														
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client34} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client34} alt="Piercing Client" />								
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client35} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client35} alt="Piercing Client" />								
						</div>

						<div className="imgContainer">
							<img className="desktopImg" onClick={(e)=> this.loadPImg(e.target.src)} src={Client36} alt="Piercing Client" />
							<img className="mobileTPImg" src={Client36} alt="Piercing Client" />								
						</div>

					</div>															

			</div>

			</div>				
			
		</div>		

		)
	}
	}